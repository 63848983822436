const Configurator = (function ($) {
  let questionaire;
  let confirmationQuestions;

  let DeviceSelectors = (function () {
    let init = function () {
      $('[data-device-template-selector]').on('submit', function () {
        $(this).find('[type="submit"]').prop('disabled', true);
        $(this).addClass('selected');
        // clear siblings
        $(this).siblings().removeClass('selected');
        $(this).siblings().find('[type="submit"]').prop('disabled', false);
      });

      $('[data-device-template-selector]').on('click', function () {
        if($(this).is('.selected')) {
          $('#specs').trigger('click');
        }
      });
    };

    return {
      init: init
    };
  }());

  let Filters = (function () {
    let selectors = {
      container: '#questionaire',
      sections: '[data-property-section], [data-condition-section]',
      values: '[data-value-selector]',
      additionalDetails: '[data-additional-details-submission]'
    };

    let init = function () {
      let elem = {};
      elem.container = $(selectors.container);
      elem.sections = elem.container.find(selectors.sections);
      elem.values = elem.container.find(selectors.values);
      let filters = elem.container.data('filters');

      displayOrderSort = function (a, b) {
        aOrder = $(a).data('display-order');
        bOrder = $(b).data('display-order');
        if (aOrder < bOrder) {
          return -1;
        }
        if (aOrder > bOrder) {
          return 1;
        }
        return 0;
      };

      sortSectionValues = function () {
        elem.sections.each(function () {
          let section = $(this);
          let valuesContainer;

          if(section.data('has-conditional')) {
            valuesContainer = section.find('[data-conditional-reveal-values-container]');
          } else {
            valuesContainer = section.find('[data-values-container]');
          }

          values = valuesContainer.children();
          values = values.sort(displayOrderSort);

          valuesContainer.append(values.filter(':not(.disabled)'));
          valuesContainer.append(values.filter('.disabled'));
        });
      };

      validateSelected = function () {
        // build list of filters
        let activeFilters = [];
        let filtersHide = elem.container.data('filters-hide');

        elem.values.filter('.selected').each(function () {
          activeFilters = activeFilters.concat(filters[$(this).data('value-id')] || []);
        });
        activeFilters = activeFilters.unique();

        elem.values.each(function () {
          let valueID = parseInt($(this).data('value-id'));

          if (activeFilters.indexOf(valueID) >= 0) {
            // value should be disabled
            $(this).addClass("disabled");
            if (filtersHide.indexOf(valueID) >= 0) {
              $(this).addClass("hidden");
            }
            $(this).find('[type="submit"]').prop("disabled", true);

            if ($(this).is('.selected')) {
              // uncheck and remove completed
              $(this).removeClass("selected");
              $(this).closest("li.completed").removeClass("completed");
            }
          } else {
            // value should be enabled
            if($(this).is('.disabled')) {
              $(this).removeClass("disabled hidden");
              $(this).find('[type="submit"]').prop("disabled", false);
            }
          }
        });

        // check every section to see if all of it's values have been disabled
        elem.sections.each(function () {
          // check if there are any values that are not disabled
          enabledValues = $(this).find(selectors.values).not('.disabled');
          // hide section
          if(enabledValues.length) {
            $(this).removeClass('hidden');
          } else {
            $(this).addClass('hidden');
          }
        });

        sortSectionValues();
      };

      updateOptionDisplay = function () {
        let display = $(this).closest('[data-property-section], [data-condition-section]').find('.selected-option-display');
        display.text($(this).data('value-text'));
      };

      elem.values.on('filters.validateselected', validateSelected);
      elem.values.on('submit', updateOptionDisplay);

      validateSelected();
    };

    return {
      init: init
    };
  }());

  let init = function() {
    Framework.Timber.eventCheck();
    Framework.Timber.tabs();

    Configurator.DeviceSelectors.init();

    // Handlers for quantity
    let quantityField = $("#configuration-quantity");
    if(quantityField.length) {
      quantityField.on('input', Configurator.helpers.updateQuantity);
    }
  };

  let helpers = (function () {
    let clearModals = function () {
      $('.modal.iziModal').remove();
    };

    let insertConfiguratorNote = function () {
      $('#configurator-note-target').children().remove();
      $('#configurator-note-target').append(Templates.create('configurator-note'));
    };

    let insertQuestionaire = function () {
      $('#questionaire-target').children().remove();
      $('#questionaire-target').append(Templates.create('questionaire'));
    };

    let insertHelpModals = function () {
      // $('.help-modal').remove();
      $('body').append(Templates.create('help-modals'));
    };

    let toggleQuestionaire = function () {
      $('#specs').parent().removeClass('disabled');
      $('#specs').trigger('click');
      let deviceName = $('[data-device-template-selector].selected').data('device-type');
      $('.intro').find('.device-type').html(deviceName);
      let deviceImgUrl = $('[data-device-template-selector].selected').find('img').attr('src');
      $('#widget-device-image').attr('src', deviceImgUrl).attr('alt', deviceName).addClass('square');
    };

    let unlockOffer = function () {
      if ($('#questionaire').is('.complete')) {
        $('.confirmation-questions-section').removeClass('hide');
      } else {
        $('.confirmation-questions-section').addClass('hide');
      }

      if ($('#questionaire').is('.complete.confirmed')) {
        let quantityField = $("#configuration-quantity");
        let value = parseInt(quantityField.val());
        let route = $('#generate-offer').data('route');

        $('#generate-offer').removeClass('disabled').prop('disabled', false).attr('href', `${route}?quantity=${value}`);
      } else {
        $('#generate-offer').addClass('disabled').prop('disabled', true).attr('href', 'javascript:void(0)');
      }
    };

    let markComplete = function () {
      $('#questionaire').addClass('complete');

      unlockOffer();
    };

    let markIncomplete = function () {
      $('#questionaire').removeClass('complete');

      unlockOffer();
    };

    let markConfirmation = function () {
      let confirmationQuestions = $('#questionaire').find('.confirmation-questions-section').find('input');
      // check if all confirmation questions have been selected
      if (!confirmationQuestions.not(':checked').length) {
        $('#questionaire').addClass('confirmed');
      } else {
        $('#questionaire').removeClass('confirmed');
      }

      unlockOffer();
    };

    let updateQuantity = function () {
      let quantityField = $("#configuration-quantity");
      let value = parseInt(quantityField.val());
      let max = parseInt(quantityField.prop('max'));
      let route = $('#generate-offer').data('route');

      if (value >= max) {
        $('.quantity-message').show();
        // update calculate url
      } else {
        $('.quantity-message').hide();
      }

      if (!$('#generate-offer').prop('disabled')) {
        $('#generate-offer').attr('href', `${route}?quantity=${value}`);
      }
    };

    let updatePosition = function () {
      // opens the correct accordion and enables appropriate accordions

      // enable accordions that follow a completed accordion
      $('[data-property-section], [data-condition-section], [data-accessory-section], [data-additional-details-section]').filter('.disabled').not('.hidden').each(function (index) {
        if ($(this).prev().is('.completed')) {
          $(this).removeClass('disabled');
        } else if ($(this).prev().is('.hidden')) {
          let prev = $(this).prev();
          if (prev.prev().is('.completed')) {
            $(this).removeClass('disabled');
          }
        }
      });

      // set accordion to active
      $($('[data-property-section], [data-condition-section], [data-accessory-section], [data-additional-details-section]').not('.disabled, .active, .completed, .hidden').get().reverse()).each(function (index) {
        if ($(this).prev().is('.hidden') || $(this).prev().is('.completed')) {
          $(this).siblings('.active').removeClass('active');
          $(this).addClass('active');
        }
      });

      if (!$('[data-property-section], [data-condition-section], [data-accessory-section], [data-additional-details-section]').filter('.active').length) {
        $('[data-accessory-section]').addClass('active');
      }
    };

    let initQuestionaire = function () {
      Framework.Timber.accordions();
      Framework.TemplateFunctions.stepAccordion();
      Framework.TemplateFunctions.iziModal();

      Configurator.Filters.init();
      updatePosition();
      let confirmationQuestions = $('#questionaire').find('.confirmation-questions-section').find('input');
      if (confirmationQuestions.length) {
        confirmationQuestions.on('change', markConfirmation);
      } else {
        $('#questionaire').addClass('confirmed');
      }
    };

    return {
      clearModals: clearModals,
      insertConfiguratorNote: insertConfiguratorNote,
      insertQuestionaire: insertQuestionaire,
      insertHelpModals: insertHelpModals,
      toggleQuestionaire: toggleQuestionaire,
      markComplete: markComplete,
      markIncomplete: markIncomplete,
      markConfirmation: markConfirmation,
      updateQuantity: updateQuantity,
      updatePosition: updatePosition,
      initQuestionaire: initQuestionaire
    };
  }());

  return {
    DeviceSelectors: DeviceSelectors,
    Filters: Filters,
    helpers: helpers,
    init: init
  };
}(jQuery));

$(document).on('turbolinks:load', function () {
  if ($('body').is('[data-js-page-id="configurator#configure"]')) {
    console.log('configurator#configure page setup');

    Configurator.init();

    // find a selected device type
    let selectedDevice = $('[data-device-template-selector].selected');
    if(selectedDevice.length == 1) {
      console.log('device selected');

      Configurator.helpers.initQuestionaire();
      selectedDevice.trigger('submit');
      Configurator.helpers.toggleQuestionaire();
    }
  }
});

window.Configurator = Configurator;

$(document).on('turbolinks:before-cache', function () {
  if ($('body').is('[data-js-page-id="configurator#configure"]')) {
    console.log('configurator#configure page teardown');
  }
});
