let ActionCable = require('@rails/actioncable');

(function () {
  let App = {};

  App.Cable = ActionCable.createConsumer('/websocket');
  console.debug("ActionCable loaded");

  if (window.App) {
    Object.assign(window.App, App);
  } else {
    window.App = App;
  }
}).call(this);

require('./channels/shipping_instruction_notifications');
