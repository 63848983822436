// add a function to register for shipping instruction updates
App.subscribe_to_shipping_instruction_notifications = function(shipment_id) {
  let successState = function (data) {
    // change button to success state
    let button = $('#instructions-print-link');
    button.attr('href', data.url);
    button.find('.button-content').html(`Click to Print Shipping Label<br><small>Trackable. Insured. Safe</small>`);
    button.removeClass('disabled loading');
    button.prop('disabled', false);
  };

  let errorState = function () {
    // TODO: create a better error state
    // change button to error state
    let button = $('#instructions-print-link');
    button.removeClass('loading');
    button.find('.button-content').html(`Something Went Wrong<br><small>We will send an email offer soon</small>`);
    $('#checkout-error-modal').iziModal("open");
  };

  App.shipping_instruction_notifications = App.Cable.subscriptions.create(
    {
      channel: "CustomerSide::ShippingInstructionNotificationsChannel",
      shipment_id: shipment_id
    },
    {
      connected: function () {
        // Called when the subscription is ready for use on the server
        console.log(`connected with shipment_instruction_notification: ${shipment_id}`);
        // Wait 30sec before closing channel, assuming an error
        App.CableTimeout = setTimeout(function () {
          // change to error state
          errorState();
          // disconnect
          App.Cable.disconnect();
        }, 30000);
      },

      disconnected: function () {
        // Called when the subscription has been terminated by the server
        console.log('disconnected');
      },

      received: function (data) {
        // cancel timeout once data received
        clearTimeout(App.CableTimeout);

        if(data.status == 'ready') {
          successState(data);
        } else if (data.status == 'error') {
          errorState();
        }

        // Close connection once data received
        App.Cable.disconnect();
      }
    }
  );
};
