const FormValidator = (function($) {
  let defaults = {
    regex: {
      emailField: /^(.+)@(.+){2,}\.(.+){2,}$/,
      phoneField: /^(\(?[0-9]{3}\)?\s?-?)?[0-9]{3}(-|\s)?[0-9]{4}$/
    }
  };
  // helpers
  let hasValue = function(element) {
    return !!element.val();
  };

  let validateEmailField = function(element) {
    return defaults.regex.emailField.test(element.val());
  };

  let validatePhoneField = function(element) {
    return defaults.regex.phoneField.test(element.val());
  };

  let validate = function(element) {
    let output = null;

    if (hasValue(element)) {
      let validator = element.data('validator');
      switch (validator) {
        case 'email':
          output = validateEmailField(element);
          break;

        case 'phone':
          output = validatePhoneField(element);
          break;

        default:
          output = true;
          break;
      }
    } else {
      output = false;
    }

    return output;
  };
  // Return module object
  return {
    validate: validate
  };
}(jQuery));

window.FormValidator = FormValidator;
