const Templates = (function($) {
  // Module defaults
  let templates = {};

  // Define private functions

  // Define public functions
  let register = function(name, templateFn) {
    // templateFn should return a properly formatted html string when executed with no args
    templates[name] = templateFn;
  };

  let create = function(name, ...passedArgs) {
    return templates[name](...passedArgs);
  };

  // Return module object
  return {
    register: register,
    create: create
  };
}(jQuery));

window.Templates = Templates;
