$(document).on('turbolinks:load', function () {
  if ($('body').is('[data-js-page-id="carts#checkout"]')) {
    console.log('carts#checkout page setup');
    App.subscribe_to_shipping_instruction_notifications($('body').data('shipment-id'));
  }
});

$(document).on('turbolinks:before-cache', function () {
  if ($('body').is('[data-js-page-id="carts#checkout"]')) {
    console.log('carts#checkout page teardown');
  }
});
