// This is a manifest file that'll be compiled into v2.js, which will include all the files
// listed below.
//
// Any JavaScript/Coffee file within this directory, lib/assets/javascripts, or any plugin's
// vendor/assets/javascripts directory can be referenced here using a relative path.
//
// It's not advisable to add code directly here, but if you do, it'll appear at the bottom of the
// compiled file. JavaScript code in this file should be added after the last require_* statement.
//
// Read Sprockets README (https://github.com/rails/sprockets#sprockets-directives) for details
// about supported directives.
//
import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"

Rails.start()
Turbolinks.start()

window.jQuery = require("jquery");
window.$ = require("jquery");
require('./libraries/jquery-ui-1.10.3')

require('./libraries/swipe')
require('./libraries/timber')
// require('./libraries/avalanche')
require('./libraries/summit')
require('./libraries/snowbridge')
require('./libraries/horizon')
require('./libraries/retinize')
require('./libraries/equalize')
require('./libraries/counter')
// require('./libraries/preloadpage')
require('./libraries/freeze')
require('./libraries/imagesLoaded-3.1.8')
require('./libraries/isotope-2.1.0')
require('./libraries/fluidvids-2.4.1')
require('./libraries/placeholder-2.3.1')
// require('./libraries/mediaelement-4.2.9')
// require('./libraries/mediaelement-and-player-4.2.9')
require('./libraries/datepicker')
require('./libraries/iziModal')
require('./libraries/template-functions')

require('./libraries/toastr')

require('./libraries/cookieconsent')


require('./custom_modules/address_validation.js')
require('./custom_modules/contact_form.js')
require('./custom_modules/form_validator.js')
require('./custom_modules/prototype_functions.js')
require('./custom_modules/templates.js')

require('./bulk_form.js')
require('./checkout.js')
require('./configurator.js')
require('./contact_support.js')
require('./contact_us.js')
require('./framework.js')
require('./request_box.js')
require('./select_offer.js')
require('./static_pages.js')
require('./user_edit.js')

require('../Actioncable/cable')