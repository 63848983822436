// Add address validation to user edit page
$(document).on('turbolinks:load', function () {
  if ($('body').is('[data-js-page-id="users#edit"]')) {
    console.log('users#edit page setup');
    AddressValidation.init();
    $("[data-address-validate]").on('addressValidation.valid', function () {
      $(this).find('[type="submit"]').prop('disabled', false);
      $(this).find('.invalid-address-message').addClass('hide');
    });

    $("[data-address-validate]").on('addressValidation.invalid', function () {
      $(this).find('[type="submit"]').prop('disabled', true);
      $(this).find('.invalid-address-message').removeClass('hide');
    });

    $("[data-address-validate]").on('addressValidation.incomplete', function () {
      $(this).find('[type="submit"]').prop('disabled', true);
      $(this).find('.invalid-address-message').addClass('hide');
    });

    $("[data-address-validate]").trigger('validate');
  }
});

$(document).on('turbolinks:before-cache', function () {
  if ($('body').is('[data-js-page-id="users#edit"]')) {
    console.log('users#edit page teardown');
  }
});
