const Framework = (function ($) {
  let Timber = {
    eventType: '',

    init: function () {
      Timber.eventCheck();
      Timber.tabs();
      Timber.accordions();
    },

    eventCheck: function () {
      if (navigator.userAgent.match(/Android/i) ||
        navigator.userAgent.match(/webOS/i) ||
        navigator.userAgent.match(/iPhone/i) ||
        navigator.userAgent.match(/iPad/i) ||
        navigator.userAgent.match(/iPod/i) ||
        navigator.userAgent.match(/BlackBerry/i) ||
        navigator.userAgent.match(/Windows Phone/i)) {
        Timber.eventType = 'tap';
        document.getElementsByTagName('body')[0].className += ' mobile';
      } else {
        Timber.eventType = 'click';
      }
    },

    tabs: function () {
      $('.tabs').each(function () {
        var tabLink = $(this).children('.tab-nav').find('li > a');
        $(this).find('.tab-panes > .active').addClass('animate-in');
        tabLink.each(function () {
          $(this).on(Timber.eventType, function () {

            // Return if disabled
            if ($(this).parent().hasClass('disabled')) return false;

            // Get references
            var tabRef = $(this).attr('href');
            var tabContent = $(this).closest('.tabs').find(tabRef);
            $(this).closest('.tab-nav').find('.active').removeClass('active');
            $(this).parent().addClass('active');
            $(this).closest('.tabs').children('.tab-panes').children('.active').removeClass('active animate-in');
            tabContent.addClass('active');

            // Force slider resize if a slider is found
            // required due to display none of tab panes
            // if (tabContent.find('.tm-slider-container').length > 0) {
            //   var tabSlider = tabContent.find('.tm-slider-container').data('avalancheSlider');
            //   tabSlider.resizeSlider();
            // }

            // Set animation timeout
            var toggleTimer = null;
            clearTimeout(toggleTimer);
            toggleTimer = setTimeout(function () {
              tabContent.addClass('animate-in');
            }, 50);
            return false;
          });
          if ($(this).parent().hasClass('active')) {
            $(this).closest('.tabs').find($(this).attr('href')).addClass('active');
          }
        });
      });
      $('.tab-trigger-click').each(function () {
        $(this).on(Timber.eventType, function () {
          var targetTab = $(this).data('target-tab');
          $('.tabs').find(targetTab).parent().removeClass('disabled');
          $('.tabs').find(targetTab).trigger(Timber.eventType);
          return false;
        });
      });
    },

    accordions: function () {
      $('.accordion').each(function () {
        var accLink = $(this).children().children().children('a');
        accLink.each(function () {
          // Add Toggle icon
          if ($(this).closest('.accordion').is('[data-toggle-icon]')) {
            if (!$(this).find('span').length && !$(this).parent().hasClass('active')) {
              $(this).prepend('<span class="' + 'icon-plus' + '" />');
            } else if (!$(this).find('span').length && $(this).parent().hasClass('active')) {
              $(this).prepend('<span class="' + 'icon-minus' + '" />');
            }
          }
          $(this).on(Timber.eventType, function () {

            // Return if disabled
            if ($(this).parent().hasClass('disabled')) return false;

            // Get target panel reference
            var link = $(this);
            var accRef = link.attr('href');
            var targetPanel = link.closest('.accordion').find(accRef);
            var allPanels = link.closest('.accordion').find('.accordion-content').parent();

            // Set height of all active
            // in case window has been resized
            link.closest('.accordion').children().children('.active').children('div').each(function () {
              var activePanelHeight = $(this).children('.accordion-content').outerHeight();
              $(this).addClass('no-transition').css({ height: activePanelHeight + 'px' });
            });

            // Toggle panels
            var toggleTimer = null;
            clearTimeout(toggleTimer);
            if (link.parent().hasClass('active')) {
              toggleTimer = setTimeout(function () {
                if (!link.closest('.accordion').is('[data-toggle-multiple]')) {
                  link.siblings('div').removeClass('no-transition').css({ height: 0 });
                } else {
                  link.siblings(targetPanel).removeClass('no-transition').css({ height: 0 });
                }
                link.parent().removeClass('active');
              }, 50);
            } else {
              toggleTimer = setTimeout(function () {
                var targetPanelHeight = link.siblings(targetPanel).find('.accordion-content').outerHeight();
                if (!link.closest('.accordion').is('[data-toggle-multiple]')) {
                  allPanels.removeClass('no-transition').css({ height: 0 });
                  link.closest('.accordion').children().children('li').removeClass('active');
                }
                link.parent().addClass('active');
                link.siblings(targetPanel).removeClass('no-transition').css({ height: targetPanelHeight + 'px' });
              }, 50);
            }

            // Toggle icon
            if (link.find('.' + 'icon-minus').length) {
              link.find('.' + 'icon-minus').removeClass('icon-minus').addClass('icon-plus');
            } else if (link.find('.' + 'icon-plus').length) {
              if (!link.closest('.accordion').is('[data-toggle-multiple]')) {
                link.closest('ul').find('.' + 'icon-minus').removeClass('icon-minus').addClass('icon-plus');
              }
              link.find('.' + 'icon-plus').removeClass('icon-plus').addClass('icon-minus');
            }
            return false;
          });
        });
        $('.accordion-trigger-click').each(function () {
          $(this).on(Timber.eventType, function () {
            var targetAcc = $(this).data('target-accordion');
            $('.accordion').find(targetAcc).parent().removeClass('disabled');
            $('.accordion').find(targetAcc).trigger(Timber.eventType);
            return false;
          });
        });

        // One window resize
        // set accordion panel to height auto
        $(window).on('resize', function () {
          $('.accordion').each(function () {
            $(this).children().children('.active').children('div').addClass('no-transition').css({ height: 'auto' });
          });
        });
      });
    }
  };

  let TemplateFunctions = {
    init: function () {
      TemplateFunctions.stepAccordion();
      TemplateFunctions.iziModal();
    },

    stepAccordion: function () {
      // handle toggling the next section
      let toggleNextSection = function (section) {
        let triggerNext = function () {
          section.addClass('completed');
          let next = section.next();
          while (next.is('.hidden')) {
            next = next.next();
          }

          if (next.length) {
            next.removeClass('disabled').children('a').trigger(Timber.eventType);
          }
        };

        let questionContainer = section.find('[data-questions-container]');
        if (questionContainer.length > 0) {
          // are the questions showing
          if (questionContainer.is('.active, .show')) {
            // check if all answers completed
            let allQuestionsAnswered = true;
            questionContainer.find('[data-question-section]').each(function () {
              let section = $(this);
              allQuestionsAnswered = ((section.find('[data-answer-selector].selected, [data-answer-skip].selected').length == 1) && allQuestionsAnswered);
            });
            if (allQuestionsAnswered) {
              triggerNext();
            }
          } else {
            // show question section
            questionContainer.addClass('active show');
            // expand top level
            section.find('.accordion-content').first().parent().css({ height: 'auto' });
          }
        } else {
          triggerNext();
        }
      };

      let markSelection = function (selector) {
        // clear previous selection
        let previousSelection = selector.siblings('.selected');
        previousSelection.removeClass('selected');
        previousSelection.find('[type="submit"]').prop('disabled', false);
        // mark this selection
        let currentSelection = selector;
        currentSelection.addClass('selected');
        currentSelection.find('[type="submit"]').prop('disabled', true);
        // trigger validation
        selector.trigger('filters.validateselected');
      };

      $('[data-property-section], [data-condition-section], [data-additional-details-section]').each(function () {
        let section = $(this);

        // handlers
        section.find('[data-value-selector], [data-answer-selector]').on('submit', function () {
          markSelection($(this));
          if (!$(this).is('[data-trigger-next="false"]')) {
            toggleNextSection(section);
          }
        });

        section.find('[data-answer-skip]').on('submit', function () {
          markSelection($(this));
          toggleNextSection(section);
        });

        section.find('[data-additional-details-response]').on('submit', function () {
          $(this).addClass('selected');
          toggleNextSection(section);
        });
      });

      $('[data-conditional-section]').each(function () {
        let section = $(this);

        // handlers
        // yes button
        section.find('[data-conditional-reveal-yes-button]').on('submit', function () {
          let otherValuesSection = section.find('[data-conditional-reveal-values-section]');
          // unselect no button
          $('[data-conditional-reveal-no-button]').removeClass('selected');
          // unselect other conditions
          let otherSelectors = otherValuesSection.find('[data-value-selector]');
          otherSelectors.removeClass('selected');
          otherSelectors.find('[type="submit"]').prop('disabled', false);
          // hide other conditions
          otherValuesSection.removeClass('active show');
          // collapse section
          section.css({ height: 'auto' });
        });

        // no button
        section.find('[data-conditional-reveal-no-button]').on('click tap', function () {
          section.parent().removeClass('completed');
          // unselect yes
          let yesSelector = section.find('[data-conditional-reveal-yes-button]');
          yesSelector.removeClass('selected');
          yesSelector.find('[type="submit"]').prop('disabled', false);
          // mark no button
          $(this).addClass('selected');
          // show other conditions
          let otherValuesSection = section.find('[data-conditional-reveal-values-section]');
          otherValuesSection.addClass('active show');
          // expand section
          section.css({ height: 'auto' });
        });
      });

      $('[data-accessory-section]').each(function () {
        let section = $(this);

        section.find('[data-accessory-selector]').on('submit', function () {
          let li = $(this);
          let selection = li.data('selection-behavior');

          if(selection == 'single') {
            li.addClass('selected');
            li.find('input[type="submit"]').prop('disabled', true);
            li.siblings().removeClass('selected');
            li.siblings().find('input[type="submit"]').prop('disabled', false);
          } else if (selection == 'multiple') {
            li.toggleClass('selected');
          }

          if(section.find('[data-accessory-selector]').filter('.selected').length > 0) {
            section.addClass('completed');
          }
        });
      });

      $('[data-question-section]').find('a[data-turbolinks="false"]').on(Timber.eventType, function (e) {
        e.preventDefault();
      });
    },

    iziModal: function () {
      $('.modal').iziModal({
        history: false,
        width: 750,
        transitionIn: 'fadeInUp',
        transitionOut: 'fadeOutDown',
        closeButton: true
      });
      $('.modal-trigger').on(Timber.eventType, function (event) {
        event.preventDefault();
        $($(this).attr('href')).iziModal('open');
      });
    },

    scrollPage: function (target, offset) {
      // Page scroll params
      let pageScrollSpeed = 1000;
      let pageEasing = 'easeInOutQuart';

      $('html, body').animate({ scrollTop: $(target).offset().top + offset }, pageScrollSpeed, pageEasing);
    }
  };

  let init = function () {
    Timber.init();
    TemplateFunctions.init();
  };

  return {
    Timber: Timber,
    TemplateFunctions: TemplateFunctions,
    init: init
  };
}(jQuery));

window.Framework = Framework;
