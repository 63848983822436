let SelectOffer = (function ($) {
  // Debounce function: Input as function which needs to be debounced and delay is the debounced time in milliseconds
  let timerId;
  let debounceFunction = function (func, delay) {
    // Cancels the setTimeout method execution
    clearTimeout(timerId);

    // Executes the func after delay time.
    timerId = setTimeout(func, delay);
  };

  outputAmount = function(amount) {
    amount = '$' + amount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return amount;
  }

  function validateCoupon() {
    // send ajax request with coupon code
    let formToken = $('input[name="authenticity_token"]').val();
    let codeInput = $('#coupon-code');
    let code = codeInput.val();
    let valueInput = $('#coupon-value');
    let couponDispalyText = $('#coupon-code-display .amount');
    let totalAmountDisplay = $('#update-amount .price .total-amount');
    let successMessage = $('#coupon-success-message');
    let errorMessage = $('#coupon-error-message');
    let valueMessage = $('#coupon-value-message');
    $.ajax({
      url: '/cart/coupon',
      method: 'POST',
      data: {
        'authenticity_token': formToken,
        code: code
      },
      success: function(data) {
        if (data.valid) {
          // show success message
          successMessage.removeClass('hidden');
          valueMessage.addClass('hidden');
          errorMessage.addClass('hidden');
        } else {
          data.error_message ? errorMessage.text(data.error_message) : errorMessage.text('Please enter a valid promo code');
          errorMessage.removeClass('hidden');
          successMessage.addClass('hidden');
        }
        // Update display
        valueInput.val(data.value);
        totalAmountDisplay.text(outputAmount(parseFloat(totalAmountDisplay.attr('calculated-value')) + parseFloat(data.value)));
        couponDispalyText.text(outputAmount(parseFloat(data.value)));
      }
    })
  }

  let init = function () {
    let methodRadios = $('[name="cart[payment_method]"]');
    let separateAddressSelector = $('#separate-payment-address');
    let paymentAddressForm = $('#user-payment-address');
    // attach handler to check payment option
    // attach handler to separate payment address input
    separateAddressSelector.on('input', function () {
      if(separateAddressSelector.is(':checked')) {
        paymentAddressForm.addClass('show');
        paymentAddressForm.find('input').not('[name="cart[payment_address][address2]"]').prop('required', true);
        paymentAddressForm.trigger('validate');
      } else {
        paymentAddressForm.removeClass('show');
        paymentAddressForm.find('input').not('[name="cart[payment_address][address2]"]').prop('required', false);
      }
    });
    // attach handlers to methods selectors
    methodRadios.on('change', (e) => {
      methodRadios.each((i, radio) => {
        if (radio.id != 'radio-payment-method-3') {
          let target = $(`${$(radio).data('target-content')}`).find('input');
          target.val('');
        }
      });
    });

    // attach handler for coupon code field
    $('#coupon-code').on('input', function() {
      debounceFunction(validateCoupon, 1000)
    })
  };

  return {
    init: init
  };
}(jQuery));

$(document).on('turbolinks:load', function () {
  if ($('body').is('[data-js-page-id="carts#show"]')) {
    console.log('carts#show page setup');
    SelectOffer.init();
    AddressValidation.init();

    let hasExpired = $('[type="submit"]').data('has-expired');

    $('[data-address-validate]').on('addressValidation.valid', function () {
      $(this).removeClass('invalid').addClass('valid');
      $(this).find('.invalid-address-message').addClass('hide');

      if (($('.show[data-address-validate]').not('.valid').length == 0) && !hasExpired) {
        $('[type="submit"]').prop('disabled', false).removeClass('disabled');
      } else {
        $('[type="submit"]').prop('disabled', true).addClass('disabled');
      }
    });

    $('[data-address-validate]').on('addressValidation.invalid', function () {
      $(this).removeClass('valid').addClass('invalid');
      $(this).find('.invalid-address-message').removeClass('hide');
      $('[type="submit"]').prop('disabled', true).addClass('disabled');
    });
    $('[data-address-validate]').on('addressValidation.incomplete', function () {
      $(this).removeClass('valid').removeClass('invalid valid');
      $(this).find('.invalid-address-message').addClass('hide');
      $('[type="submit"]').prop('disabled', true).addClass('disabled');
    });

    $('.cash-offer').trigger('change');
    $('[data-address-validate]').trigger('validate');
  }
});

$(document).on('turbolinks:before-cache', function () {
  if ($('body').is('[data-js-page-id="carts#show"]')) {
    console.log('carts#show page teardown');
  }
});

$(document).on('turbolinks:load', function () {
  $('#radio-check-business-1, #radio-check-business-2').on('click', function () {
    $('#usps-disclaimer').show();
  });
  $('#radio-check-business-3').on('click', function () {
    $('#usps-disclaimer').hide();
  });
});
