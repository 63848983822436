let BulkForm = (function ($) {
  // Module defaults
  let defaults = {
    classes: {
      container: 'bulk-form',
      checkboxes: 'device-type-checkbox',
      showFormButton: 'show-bulk-form',
      invalidInput: 'invalid-form-input'
    },
    ids: {
      userDetailsSection: 'tabs-customer-details-pane',
      deviceDetailsSection: 'tabs-buy-back-form'
    },
    dataAttributes: {}
  };

  // Initialize modules
  let init = function (element = null, options = {}) {
    let s = {};
    $.extend(true, s, defaults, options);

    let setup = function (container) {
      let elem = {};
      elem.showFormButton = container.find('.' + s.classes.showFormButton);
      elem.userDetailsSection = container.find('#' + s.ids.userDetailsSection);
      elem.fileSelector = container.find('.file-selector');
      elem.submitFileButton = container.find('input.file-submit[type="submit"]');
      elem.submitFormButton = container.find('input.form-submit[type="submit"]');
      elem.deviceDetailsSection = container.find('#' + s.ids.deviceDetailsSection);

      // Setup file name handler
      elem.fileSelector.each(function () {
        let input = $(this).find('input');
        let text = $(this).find('.file-selector-text');

        input.on('change', function (e) {
          let name = e.target.files[0].name;
          text.text(name);
        });
      });

      // Setup user details validation
      elem.showFormButton.hide();
      elem.submitFileButton.hide();
      elem.userDetailsSection.on('bulkForm.validateUserDetails', function () {
        let formValid = true;
        // check that all the fields are completed
        let inputs = $(this).find('input').not('[type="radio"]');
        inputs.each(function() {
          if($(this).prop('required')) {
            if($(this).data('edited')) {
              let isValid = FormValidator.validate($(this));
              formValid = isValid && formValid;
              if(!isValid && $(this).is(':focus')) {
                $(this).addClass(s.classes.invalidInput);
              } else if(isValid) {
                $(this).removeClass(s.classes.invalidInput);
              }
            } else {
              let isValid = FormValidator.validate($(this));
              formValid = isValid && formValid;
            }
          }
        });
        // make sure a payment option is selected
        formValid = ($(this).find('[name="payment_type"]:checked').length == 1) && formValid;
        if(formValid) {
          elem.showFormButton.show();
          // show file submit if file is selected
          if (elem.fileSelector.find('input').val()) {
            elem.submitFileButton.show();
          }
        } else {
          elem.showFormButton.hide();
          elem.submitFileButton.hide();
        }
      });

      // trigger validation on input
      elem.userDetailsSection.find('input').on('input', function () {
        $(this).data('edited', true);
        elem.userDetailsSection.trigger('bulkForm.validateUserDetails');
      });
    };

    if (element) {
      if (element.hasClass(s.classes.container)) {
        // init specific element if it has the correct class
        setup(element);
      }
    } else {
      // init all existing elements
      $('.' + s.classes.container).each(function () {
        setup($(this));
      });
    }
  };

  // Return module object
  return {
    defaults: defaults,
    init: init
  };
}(jQuery));

$(document).on('turbolinks:load', function () {
  if ($('body').is('[data-js-page-id="static_pages#bulk_buy_back"]')) {
    console.log('static_pages#bulk_buy_back page setup');
    BulkForm.init();
  }
});
