const ContactForm = (function ($) {
  let defaults = {
    classes: {
      container: 'contact-form',
      invalidInput: 'invalid-form-input'
    }
  };

  let init = function (element = null, options = {}) {
    let s = {};
    $.extend(true, s, defaults, options);

    let setup = function (container) {
      let elem = {};
      elem.inputs = container.find('input, textarea, select');
      // check fields
      container.on('contactForm.validateDetails', function () {
        let formValid = true;

        elem.inputs.each(function () {
          if ($(this).data('edited')) {

            let isValid = FormValidator.validate($(this));

            // Allow empty non-required fields
            if(!$(this).prop('required') && !$(this).val()) {isValid = true;}

            formValid = isValid && formValid;

            let classTarget = $(this);
            if ($(this).is('select')) {classTarget = $(this).parent()};

            if (!isValid && $(this).is(':focus')) {
              classTarget.addClass(s.classes.invalidInput);
            } else if (isValid) {
              classTarget.removeClass(s.classes.invalidInput);
            }
          } else {
            formValid = false;
          }
        });
      });

      // trigger validation on input
      elem.inputs.on('input', function () {
        $(this).data('edited', true);
        container.trigger('contactForm.validateDetails');
      });
    };

    if (element) {
      if (element.hasClass(s.classes.container)) {
        // init specific element if it has the correct class
        setup(element);
      }
    } else {
      // init all existing elements
      $('.' + s.classes.container).each(function () {
        setup($(this));
      });
    }
  };

  return {
    defaults: defaults,
    init: init
  };
}(jQuery));

window.ContactForm = ContactForm;
