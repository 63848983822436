const AddressValidation = (function ($) {
  let init = function () {
    console.log("Address validation init");

    const SmartyStreetsCore = SmartyStreetsSDK.core;
    const Lookup = SmartyStreetsSDK.usStreet.Lookup;

    let websiteKey = "17843414539904121";
    // let websiteKey = "17843416674544325";  // key for localhost testing
    const credentials = new SmartyStreetsCore.SharedCredentials(websiteKey);
    let client = SmartyStreetsCore.buildClient.usStreet(credentials);

    $("[data-address-validate]").each(function () {
      let container = $(this);

      let timerId;

      let fields = {
        street: container.find("[data-address='street']"),
        secondary: container.find("[data-address='secondary']"),
        city: container.find("[data-address='city']"),
        state: container.find("[data-address='state']"),
        zipcode: container.find("[data-address='zip_code']")
      };

      let skipValidation = container.data('skip-validation');

      // Debounce function: Input as function which needs to be debounced and delay is the debounced time in milliseconds
      let debounceFunction = function (func, delay) {
        // Cancels the setTimeout method execution
        clearTimeout(timerId);

        // Executes the func after delay time.
        timerId = setTimeout(func, delay);
      };

      let markStatus = function (status) {
        switch (status) {
          case 'valid':
            container.trigger('addressValidation.valid');
            break;

          case 'invalid':
            container.trigger('addressValidation.invalid');
            break;

          case 'incomplete':
            container.trigger('addressValidation.incomplete');
            break;
        }
      };

      let validate = function () {
        // verify all fields are filled
        let street = fields.street.val().trim();
        let secondary = fields.secondary.val().trim();
        let city = fields.city.val().trim();
        let state = fields.state.val().trim();
        let zipcode = fields.zipcode.val().trim();

        let handleSuccess = function (response) {
          if (response.lookups[0].result.length) {
            // add zipcode check to match the server side check
            if (response.lookups[0].result[0].components.zipCode == zipcode.substring(0, 5)) {
              markStatus('valid');
            } else {
              markStatus('invalid');
            }
          } else {
            markStatus('invalid');
          }
        };

        let handleError = function (response) {
          console.log(response);
          markStatus('invalid');
        };

        if (street && city && state && zipcode) {
          if (skipValidation) {
            markStatus('valid');
          } else {
            // send request if all required fields are filled
            let lookup = new Lookup();
            lookup.street = street;
            lookup.secondary = secondary;
            lookup.city = city;
            lookup.state = state;
            lookup.zipcode = zipcode;

            console.log('lookup sent');
            client.send(lookup)
              .then(handleSuccess)
              .catch(handleError);
          }
        } else {
          markStatus('incomplete');
        }
      };


      // attach debounced validation call
      for (const field in fields) {
        fields[field].on('input', function () {
          debounceFunction(validate, 1000);
        });

        container.on('validate', function () {
          validate();
        });
      }
    });

  };
  // Return module object
  return {
    init: init
  };
}(jQuery));

window.AddressValidation = AddressValidation;

